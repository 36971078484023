import './Sidebar.css';

import { useState } from 'react';

import logo from "../logos/placa-logo.png"
import { FaHome } from "react-icons/fa";

const Sidebar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const closeSidebar = () => {
        setIsOpen(false);
    };

    return (
        <>
            <div className={`sidebar ${isOpen ? 'open' : ''}`}>
                <div className="logo">
                    <img src={logo} alt='ZapCheck' style={{ maxWidth: 150 }} />
                </div>
                <div className="menu">
                    <div className="menu-item" onClick={closeSidebar}>
                        <FaHome style={{ marginRight: 10 }} /> Home
                    </div>
                </div>
                {/* <button className="logout-button" onClick={closeSidebar}>Sair</button> */}
            </div>
            <div className={`hamburger ${isOpen ? 'hide' : ''}`} onClick={toggleSidebar}>
                ☰
            </div>
            {isOpen && <div className="overlay" onClick={closeSidebar}></div>}
        </>
    );
};

export default Sidebar;
