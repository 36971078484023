import './App.css';
import { useState } from 'react';
import LoadingIcons from 'react-loading-icons'
import { Bounce, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from './components/Sidebar';
import VehicleData from './components/VehicleData';
import { ZapCheckApi } from './api';

const zapCheckClient = new ZapCheckApi();

const App = () => {
    const [plate, setPlate] = useState<string | null>(null);
    const [currentPlate, setCurrentPlate] = useState<string | null>(null);
    const [plateLoading, setPlateLoading] = useState<boolean>(false);
    const [plateInfo, setPlateInfo] = useState<object | null>(null);
    const [plateInfoExtra, setPlateInfoExtra] = useState<object | null>(null);
    const [fipeInfo, setFipeInfo] = useState<object | null>(null);

    const readPlate = (evt: any) => {
        const value = evt.target.value;
        setPlate(value);
    }

    const showError = (message: string = 'Falha ao buscar placa, tente novamente mais tarde.') => {
        toast.error(message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "dark",
            transition: Bounce,
        });
            
    }

    const handleClick = async (evt: any) => {
        evt.preventDefault();

        if(!plate || plateLoading) {
            return;
        }

        try {
            setPlateInfo(null);
            setPlateInfoExtra(null);
            setFipeInfo(null);
            setPlateLoading(true);
            const [plateInfo, fipeInfo] = await Promise.allSettled([zapCheckClient.fetchBasicPlateInfo(plate), zapCheckClient.fetchBasicFipeInfo(plate)]);

            if(plateInfo.status === "rejected" && fipeInfo.status === "rejected") {
                throw plateInfo.reason;
            }

            if(plateInfo.status === "fulfilled") {
                const { extra, ...restPlateInfo } = plateInfo.value;
                setPlateInfo(restPlateInfo);
                setPlateInfoExtra(extra);
            }
            setFipeInfo(fipeInfo.status === "fulfilled" ? fipeInfo.value : null);

            setCurrentPlate(plate);
            setPlate(null);
        } catch(error: any) {
            console.error(error?.response?.data);
            showError(error?.response?.data?.message);
        } finally {
            setPlateLoading(false);
        }
    }

    return (
        <div className="app">
            <Sidebar />
            <div className="content">
                <h2 style={{ marginBottom: 2 }}>Bem-vindo ao ZapCheck!</h2>
                <p style={{ marginTop: 2 }}>Faça sua busca informando a placa.</p>
                <form className="container">
                    <input type="text" id="car-plate" className="plate-input" maxLength={7} pattern="[A-Za-z]{7}" placeholder="Placa" title="Placa" onChange={evt => readPlate(evt)} value={plate ?? ""} disabled={plateLoading} />
                    <button type='submit' className="search-button" onClick={evt => handleClick(evt)} disabled={plateLoading} >
                        {plateLoading ? <LoadingIcons.Puff stroke="#007bff" style={{ maxHeight: 24, maxWidth: 24 }} /> : "Buscar" }
                    </button>
                </form>
                { (!plateLoading && (plateInfo || fipeInfo)) && <VehicleData plate={currentPlate!.toUpperCase()} plateInfo={plateInfo} plateInfoExtra={plateInfoExtra} fipeInfo={fipeInfo} /> }
            </div>
            <ToastContainer />
        </div>
    );
};

export default App;
