import "./VehicleData.css";
import qrcode from "../logos/contact-img.png"

const link = "https://wa.me/5541998586056?text=Ol%C3%A1,%20gostaria%20de%20fazer%20uma%20consulta%20detalhada%20de%20um%20ve%C3%ADculo%20de%20placa:%20";

const translateVehicleKey = (key: string) => {
    if(key === "marca") return "Marca";
    if(key === "modelo") return "Modelo";
    if(key === "sub_modelo") return "Sub-Modelo";
    if(key === "versao") return "Versāo";
    if(key === "cor") return "Cor";
    if(key === "ano") return "Ano";
    if(key === "ano_modelo") return "Ano Modelo";
    if(key === "tipo_veiculo") return "Tipo de Veículo";
    if(key === "nacionalidade") return "Nacionalidade";
    if(key === "placa_atual") return "Placa Atual";
    if(key === "placa_modelo_antiga") return "Placa Modelo Antiga";
    if(key === "placa_modelo_nova") return "Placa Modelo Nova";
    if(key === "cidade") return "Cidade";
    if(key === "estado") return "Estado";
    
    return key;
}

const translateExtraVehicleKey = (key: string) => {
    if(key === "chassi") return "Número do Chassi";
    if(key === "cilindradas") return "Cilindradas";
    if(key === "combustivel") return "Combustível";
    if(key === "potencia") return "Potência";
    if(key === "numero_passageiros") return "Número de Passageiros";
    if(key === "tipo_doc_faturado") return "Tipo Documento Faturado";
    if(key === "estado_faturado") return "Estado Faturado";
    if(key === "tipo_doc_proprietario") return "Tipo Documento Proprietário";
    if(key === "estado_proprietario") return "Estado Proprietário";
    
    return key;
}

const parseValue = (value: any) => {
    // eslint-disable-next-line
    const valueStr = (value as string).replace(/[^\x00-\x7F]+/g, '');
    if(!valueStr) return "-";
    if(valueStr === "0") return "-";
    if(valueStr.startsWith(`R$ 0`) || valueStr.startsWith(`R$0`)) return "-";
    if(valueStr === "000000-00") return "-";
                
    return value;
}

const translateFipeKey = (key: string) => {
    if(key === "valor") return "Valor";
    if(key === "ipva") return "IPVA";
    if(key === "mes_referencia") return "Mês de Referência";
    if(key === "codigo_fipe") return "Código FIPE";
    
    return key;
}

const VehicleData = ({ plate, plateInfo, plateInfoExtra, fipeInfo }: { plate:string; plateInfo: any, plateInfoExtra: any, fipeInfo: any }) => {
    return (
        <div className="vehicle-data">
            <div className="column">
                <h3>Dados Básicos</h3>
                <hr />
                {plateInfo ? (
                    <ul className="item-list">
                        {Object.entries(plateInfo).map(([key, value]) => (
                            <li key={key}>
                                <div className="item-key">{translateVehicleKey(key)}</div>
                                <div className="item-value">{parseValue(value)}</div>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>Nenhum dado disponível.</p>
                )}
                <h3 style={{ marginTop: 15 }}>Dados Extras</h3>
                <hr />
                {plateInfoExtra ? (
                    <ul className="item-list">
                        {Object.entries(plateInfoExtra).map(([key, value]) => (
                            <li key={key}>
                                <div className="item-key">{translateExtraVehicleKey(key)}</div>
                                <div className="item-value">{parseValue(value)}</div>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>Nenhum dado disponível.</p>
                )}
            </div>
            <div className="column">
                <h3>FIPE</h3>
                <hr />
                {fipeInfo ? (
                    <ul className="item-list">
                        {Object.entries(fipeInfo).map(([key, value]) => (
                            <li key={key}>
                                <div className="item-key">{translateFipeKey(key)}</div>
                                <div className="item-value">{parseValue(value)}</div>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>Nenhum dado disponível</p>
                )}
                { plateInfo && plateInfoExtra && fipeInfo && (
                    <>
                        <h3 style={{ marginTop: 48, color: "#007bff" }}>Para mais informações como:</h3>
                        <hr />
                        <ul className="item-list">
                            <li>
                                <div className="item-key">Consulta na Base Estadual/Nacional</div>
                                <div className="item-value">Débitos de IPVA e Multas</div>
                            </li>
                            <li>
                                <div className="item-key">Proprietário Atual</div>
                                <div className="item-value">Nome e documento do atual proprietário</div>
                            </li>
                            <li>
                                <div className="item-key">Leilão</div>
                                <div className="item-value">Passagem por leilāo, número do lote e fotos</div>
                            </li>
                            <li>
                                <div className="item-key">Roubo/Furto</div>
                                <div className="item-value">Alerta de roubo/furto</div>
                            </li>
                            <li>
                                <div className="item-key">Gravame</div>
                                <div className="item-value">Informações de gravame</div>
                            </li>
                            <li>
                                <div className="item-key">Renajud</div>
                                <div className="item-value">Bloqueio e restriçāo judicial</div>
                            </li>
                            <li>
                                <div className="item-key-img">Entre em <a href={`${link}${plate}`} rel="noreferrer" target="_blank">contato</a> pelo QR Code abaixo:</div>
                                <div className="item-value-img">
                                    <img src={qrcode} alt="Entre em contato!" />
                                </div>
                            </li>
                        </ul>
                    </>
                ) }
            </div>
        </div>
    );
};

export default VehicleData;
