import axios, { AxiosInstance } from "axios";
import axiosRetry from "axios-retry";

class ZapCheckApi {
    private readonly client: AxiosInstance;
    constructor() {
        this.client = axios.create({
            baseURL: process.env.REACT_APP_API_BASE_URL,
            headers: {
                "Authorization": `Bearer ${process.env.REACT_APP_API_KEY}`
            },
            timeout: 30000
        });
        axiosRetry(this.client, {
            retries: 3,
            retryDelay: retryCount => 2 ** retryCount * 1000,
        });
    }
    
    async fetchBasicPlateInfo(plate: string) {
        const { data } = await this.client.get(`/plate/basic/${plate}`);
        return data;
    }

    async fetchBasicFipeInfo(plate: string) {
        const { data } = await this.client.get(`/plate/fipe/${plate}`);
        return data;
    }
}

export { ZapCheckApi };